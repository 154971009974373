import React from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Radio,
  Switch,
  Button,
  Checkbox,
  TextField,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import dayjs from "dayjs";
import _, { __ } from "lodash";
import { MuiChipsInput } from "mui-chips-input";
import ModalAlert from "./popup-alert.component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { airportSiteSet } from "../config/data/airport";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { optionCategoryStatus } from "../config/data/status";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "dayjs/locale/en-gb";
const calRatio = ({ width, height, imageType, maxSizeInMB }) => {
  const findExpectRatio = imageType.split(":");
  const expectRatio = (findExpectRatio[0] / findExpectRatio[1]).toFixed(2);
  const imageRatio = (width / height).toFixed(2);
  if (expectRatio - imageRatio > -0.05 && expectRatio - imageRatio < 0.05) {
    return false;
  }
  return `Upload error. Please Check image ratio ${findExpectRatio[0]} : ${findExpectRatio[1]} and size(max ${maxSizeInMB}MB.)`;
};

export default function CMSInput(props) {
  const {
    value = "",
    label = "",
    labelFor = true,
    labelText = label,

    inputType = "text",
    error = false,
    required = false,
    condition = false,
    disableForm = false,

    onChangeInput = () => {},

    // For Selct input
    optionInput = [],
    disablePortal = true,
    disableClearable = false,

    // For file input
    fixedImageRatio = false,
    allowImageType = ".png, .jpg, .jpeg, .gif",
    imageCkEditor = false,
  } = props;

  const today = dayjs();

  const checkInputType = () => {
    switch (inputType) {
      case "text":
        return (
          <TextField
            label={label}
            fullWidth
            value={value}
            variant="outlined"
            onChange={(e) => {
              onChangeInput(e.target.value);
            }}
            disabled={disableForm}
            error={error}
          />
        );
      case "number":
        return (
          <TextField
            label={label}
            type="number"
            fullWidth
            value={value}
            variant="outlined"
            onChange={(e) => {
              onChangeInput(e.target.value);
            }}
            disabled={disableForm}
            error={error}
          />
        );

      case "chip":
        return (
          <Box mt={1}>
            <MuiChipsInput
              label={label}
              value={value}
              onChange={(e) => {
                let temp = [];
                e.forEach((row) => {
                  temp.push(row.split(";"));
                });
                onChangeInput(_.uniq(_.flatten(temp)));
              }}
              fullWidth
              disabled={disableForm}
              error={error}
            />
          </Box>
        );

      case "file":
        const raitio = fixedImageRatio
          ? fixedImageRatio.split(":")
          : "1:1".split(":");

        const expectRatio = raitio[0] / raitio[1];
        return (
          <Box>
            <Box
              sx={{
                border: 1,
                width: 200,
                height: 200,
                borderRadius: 2,
                borderColor: error ? "red !important" : "",
                borderStyle: "dashed",
              }}
              className="center-item-wrapper border-color-gray"
            >
              {value ? (
                <img
                  src={value?.name ? URL.createObjectURL(value) : value}
                  style={{
                    height: !value ? 200 : 200 / expectRatio,
                    width: !value ? 200 : 200 * expectRatio,
                    maxHeight: 200,
                    maxWidth: 200,
                  }}
                  alt=""
                />
              ) : (
                <Button
                  component="label"
                  className="center-item-wrapper"
                  sx={{ flexDirection: "column", cursor: "pointer" }}
                >
                  <UploadFileIcon className="text-color-blue" />
                  <Typography className="text-color-blue">
                    Upload Image
                  </Typography>
                  <input
                    hidden
                    type="file"
                    accept={allowImageType}
                    disabled={disableForm}
                    onChange={(e) => {
                      let img = new Image();
                      img.src = URL.createObjectURL(e.target.files[0]);
                      const maxFileSizeInMB = 2;
                      const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
                      if (e.target.files[0].size > maxFileSizeInKB) {
                        // Check file size if more 2mb. Set width 0 to show Error
                        img.width = 0;
                      }
                      const allowType = allowImageType.replace(/[^\w\s]/gi, "");
                      if (
                        !allowType.includes(
                          e.target.files[0].type.split("/").pop()
                        )
                      ) {
                        ModalAlert({
                          iconType: "error",
                          title: "NotAllow This image type",
                        });
                        return;
                      }
                      img.onload = function handleLoad() {
                        if (fixedImageRatio) {
                          const notAllow = calRatio({
                            width: img.width,
                            height: img.height,
                            imageType: fixedImageRatio,
                            maxSizeInMB: maxFileSizeInMB,
                          });
                          if (notAllow) {
                            ModalAlert({ iconType: "error", title: notAllow });
                            return;
                          }
                        }

                        onChangeInput(e.target.files[0]);
                      };
                    }}
                  />
                </Button>
              )}
            </Box>
            {!disableForm ? (
              value ? (
                <Box my={1}>
                  <DeleteOutlineIcon onClick={() => onChangeInput("")} />
                </Box>
              ) : (
                <Box my={2}>
                  <Typography
                    fontSize={14}
                  >{`${allowImageType.toUpperCase()} (max. 2MB)`}</Typography>
                </Box>
              )
            ) : null}
          </Box>
        );

      case "select":
        const checkLabel =
          label || (condition === "userGroup" ? "User group" : false);
        return (
          <Autocomplete
            disablePortal
            value={optionInput.find((row) => row.value == value) || ""}
            options={optionInput}
            onChange={(e, newValue) => onChangeInput(newValue?.value || "")}
            renderInput={(params) => (
              <TextField {...params} label={checkLabel} error={error} />
            )}
            disabled={disableForm}
            isOptionEqualToValue={(option) => option.value === value || true}
            getOptionDisabled={(option) => option.disabled}
            disableClearable={disableClearable}
            renderOption={(props, option) => (
              <Box
                {...props}
                sx={{
                  backgroundColor: "#ffffff !important",
                  "&:hover": {
                    background: "#f1f1f1 !important",
                  },
                }}
              >
                {option.label}
              </Box>
            )}
          />
        );

      case "selectStatus":
        const statusOption = [...optionInput, ...optionCategoryStatus];

        return (
          <Autocomplete
            value={statusOption.find(
              (row) => row.label == value || row.value == value
            )}
            disablePortal={false}
            disableClearable={true}
            options={statusOption}
            onChange={(e, newValue) => onChangeInput(newValue?.value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={disableForm}
            renderInput={(params) => {
              const paramsValue = params.inputProps.value;
              const match = statusOption.find(
                (i) => i.label === paramsValue || i.value === paramsValue
              ); // For get status color
              return (
                <TextField
                  {...params}
                  error={error}
                  label={label}
                  sx={{
                    input: {
                      backgroundColor: match?.backgroundColor,
                      color: match?.textColor,
                      borderRadius: "16px",
                      textAlign: "center",
                      fontSize: "14px",
                      maxWidth: "60px",
                      px: 1,
                    },
                  }}
                />
              );
            }}
            renderOption={(props, option) => (
              <Box {...props}>
                <Typography
                  sx={{
                    backgroundColor: `${option?.backgroundColor}`,
                    color: `${option?.textColor}`,
                    borderRadius: "16px",
                    fontSize: "14px",
                    px: 1,
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            )}
          />
        );

      case "switch":
        return (
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(value)}
                onChange={(e) => onChangeInput(e.target.checked)}
                disabled={disableForm}
                error={error}
              />
            }
            label={label}
          />
        );
      case "switchSubCat":
        return (
          <Box position={"absolute"} left={"26%"} marginTop={"8%"}>
            <Switch
              checked={Boolean(value)}
              onChange={(e) => onChangeInput(e.target.checked)}
              disabled={disableForm}
              error={error}
            />
            <span>{value ? "Active" : "Inactive"}</span>
          </Box>
        );

      case "dateTime":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DemoContainer
              components={["DateTimePicker"]}
              sx={{ pt: 0, overflow: "inherit" }}
            >
              <DateTimePicker
                label={label}
                value={dayjs(value)}
                minDateTime={dayjs()}
                disabled={disableForm}
                sx={{ minWidth: "0 !important" }}
                onChange={(e) => onChangeInput(e)}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );
      case "datePicker":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DemoContainer
              components={["DatePicker"]}
              sx={{ pt: 0, overflow: "inherit" }}
            >
              <DatePicker
                label={label}
                value={dayjs(value)}
                disabled={disableForm}
                sx={{ minWidth: "0 !important" }}
                onChange={(e) => onChangeInput(e)}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );

      case "time":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DemoContainer
              components={["TimePicker"]}
              sx={{ pt: 0, overflow: "inherit" }}
            >
              <TimePicker
                label={label}
                value={dayjs(value, "HH:mm:ss")}
                disabled={disableForm}
                onAccept={(e) => onChangeInput(dayjs(e, "HH:mm:ss"))}
                onChange={(e) => onChangeInput(dayjs(e, "HH:mm:ss"))}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        );

      case "richText":
        function cleanImageTags(htmlContent) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlContent, "text/html");

          // Select all img tags
          const imgTags = doc.querySelectorAll("img");

          // Loop through each img tag and remove width and height attributes
          imgTags.forEach((img) => {
            img.removeAttribute("width");
            img.removeAttribute("height");
          });

          // Return the modified HTML
          return doc.body.innerHTML;
        }
        function uploadAdaptor(loader) {
          return {
            upload: async () => {
              try {
                const body = new FormData();
                const file = await loader.file;

                body.append("image", file);
                body.append("path", "textEditor");
                const pathUrl = "image";
                const config = {
                  method: "post",
                  url: `${process.env.API_BACKBONE}/${pathUrl}`,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  data: body,
                };

                const upload = await axios(config);
                const fileLocation = upload.data.payload.fileLocation;

                let responsiveImageHtml = `
                  <figure class="image">
                    <img src="${fileLocation}" style="aspect-ratio: inherit;" alt="Uploaded Image">
                  </figure>
                `;

                responsiveImageHtml = cleanImageTags(responsiveImageHtml);

                return {
                  default: fileLocation,
                  html: responsiveImageHtml,
                };
              } catch (error) {
                console.error("Image upload failed:", error);
                throw error;
              }
            },
          };
        }

        function uploadPlugin(editor) {
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new uploadAdaptor(loader);
          };
        }
        const handleEditorChange = (event, editor) => {
          let data = editor.getData();
          data = cleanImageTags(data); // Clean the data before handling it
          onChangeInput(data);
        };
        return (
          <Box
            mt={1}
            sx={{
              ".ck-editor": {
                border: error ? "1px solid red !important" : "",
              },
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={value}
              onBlur={handleEditorChange}
              config={{
                extraPlugins: [uploadPlugin],
                removePlugins: ["MediaEmbed", "EasyImage"],
              }}
              disabled={disableForm}
            />
          </Box>
        );

      case "airportSelect":
        return (
          <Grid container spacing={2}>
            {airportSiteSet.map((row, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value[row.value]}
                        onChange={(e, newValue) => {
                          value[row.value] = newValue;
                          onChangeInput(value);
                        }}
                        name={row.value}
                        disabled={disableForm}
                        sx={{ color: error ? "#D32F2F" : "#0E1013" }}
                      />
                    }
                    label={row.label}
                    className={`${error ? "text-color-red" : ""}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        );

      case "frequencySelect":
        const [frequency, setFrequency] = React.useState(
          value === 0 ? 0 : "everyDay"
        );
        const [days, setDays] = React.useState(value === 0 ? "" : value);

        const handleFrequencyChange = (newFrequency, daysValue = "") => {
          setFrequency(newFrequency);
          setDays(daysValue);
          if (newFrequency === "everyDay") {
            onChangeInput(daysValue);
          } else {
            onChangeInput(newFrequency);
          }
        };
        return (
          <Box>
            <RadioGroup
              value={frequency}
              onChange={(e) => handleFrequencyChange(e.target.value)}
            >
              <FormControlLabel
                value={0}
                control={<Radio disabled={disableForm} />}
                label="Always"
                onClick={() => handleFrequencyChange(0)}
              />
              <FormControlLabel
                value="everyDay"
                control={<Radio disabled={disableForm} />}
                label="Seen every (Day)"
              />
            </RadioGroup>

            {frequency === "everyDay" && (
              <TextField
                label="Number of days"
                type="number"
                value={days}
                variant="outlined"
                onChange={(e) => {
                  let value = e.target.value;
                  const numberValue = parseInt(value, 10);
                  if (value === "0" || numberValue < 1) {
                    value = "1"; // Set to 1 if the value is 0 or less
                  }
                  setDays(value);
                  onChangeInput(value);
                }}
                disabled={disableForm}
                error={error}
                InputProps={{ inputProps: { min: 1 } }}
              />
            )}
          </Box>
        );

      case "radioGroup":
        return (
          <FormControl>
            <RadioGroup defaultValue={value} row>
              {optionInput.map((row, index) => {
                return (
                  <FormControlLabel
                    value={row.value}
                    control={
                      <Radio
                        onChange={(e) => onChangeInput(e.target.value)}
                        disabled={disableForm}
                      />
                    }
                    label={row.label}
                    key={index}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );

      case "color":
        return (
          <>
            <TextField
              label={label}
              fullWidth
              value={value}
              variant="outlined"
              onChange={(e) => {
                onChangeInput(e.target.value);
              }}
              disabled={disableForm}
              error={error}
              type="color"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: value,
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </>
        );

      case "hide":
        return;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {labelFor && (
        <Typography>
          {labelText} {required && <span style={{ color: "#FF0000" }}>*</span>}
        </Typography>
      )}
      <Box my={2}>{checkInputType()}</Box>
    </Box>
  );
}
